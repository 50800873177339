<div class="task-main">
  <div class="name-container">
    <commentable *ngIf="!editName" isEllipsisActive [c-entity]="task" [c-key]="'name'">
      <span class="task-name">{{task.name}}</span>
    </commentable>
    <scope-ui-input
      *ngIf="editName"
      class="edit-name"
      (click)='$event.stopPropagation()'
      [initialValue]="task.name"
      [hasPlaceholder]="false"
      [autofocus]='true'
      (onInputBlurChange)="updateTaskName($event)"
    />
    <button
      [hidden]="editName"
      class="scope-actions-button"
      (click)="$event.stopPropagation()"
      [matMenuTriggerFor]="taskOptionsMenu">
      <mat-icon class="material-symbols-rounded">more_vert</mat-icon>
    </button>
    <mat-menu
      #taskOptionsMenu="matMenu"
      backdropClass="scope-options-menu">
      <ng-container *ngFor="let option of taskActions">
        <mat-divider *ngIf="option.showDivider && (option.isVisible ? option.isVisible(currentScope) : true)"></mat-divider>
        <button
          (click)="option.click()"
          *ngIf="option.isVisible ? option.isVisible(currentScope) : true"
          mat-menu-item
          class="scope-option">
          <mat-icon class="material-symbols-rounded orange">{{ option.icon }}</mat-icon>
          <span class="scope-option-name">{{ option.name() }}</span>
        </button>
      </ng-container>
    </mat-menu>
  </div>
  <scope-ui-table
    *ngIf="mappedDepartmentsDataSource"
    class="departments-table-wrapper"
    scroll-siblings
    [target-class]="'departments-table-wrapper'"
    [tableClass]="'departments-table overview-table condensed row-gaps'"
    [loggedInUser]='currentUser'
    [data]="mappedDepartmentsDataSource"
    [displayedColumns]="departmentColumns"
    [noPagination]="true"
    [noPreferences]="true"
    [showToggleMenu]="false"
    [expandable]="true"
    [headerExpandable]="true"
    [tableExpanded]="tableExpanded"
    [expandedElements]="scopeTabService.departmentSelectedStates$ | async"
    [useSourceId]="true"
    [hideToggles]="true"
    [sort]="departmentSort"
    [stickyHeader]="true"
    [optimise]="true">
    <ng-template let-element let-sort="sort">
      <scope-ui-table
        [tableClass]="'roles-table nested condensed'"
        [loggedInUser]='currentUser'
        [data]="element.children"
        [displayedColumns]="departmentColumns"
        [noPagination]="true"
        [noPreferences]="true"
        [showToggleMenu]="false"
        [expandable]="false"
        [hideHeader]="true"
        [sort]="sort"
        [optimise]="true"/>
    </ng-template>
  </scope-ui-table>
  <div class="task-footer-wrapper">
    <div *ngIf="tableExpanded" class="task-footer-shadow"></div>
  </div>
  <div class="task-footer">
    <div>
      <label>Quantity</label>
      <scope-ui-counter [hidden]="!scopeApprovalFlowService.isScopeEditable(currentScope)"
                        [initialValue]="task.quantity"
                        [min]="1"
                        [formClass]="'extra-small'"
                        (onUpdateValue)="onUpdateQuantity($event)"></scope-ui-counter>
      <span [hidden]="scopeApprovalFlowService.isScopeEditable(currentScope)">{{task.quantity}}</span>
    </div>
    <mat-divider [has-privilege]="'SCOPE_MARGIN__VIEW'"></mat-divider>
    <div [has-privilege]="'SCOPE_MARGIN__VIEW'">
      <label>Margin</label>
      <span>{{task.getMargin()}}%</span>
    </div>
    <mat-divider [has-privilege]="'RATECARD_ROLE_COST__VIEW'"></mat-divider>
    <div [has-privilege]="'RATECARD_ROLE_COST__VIEW'">
      <label>Cost Price</label>
      <commentable [c-entity]="task" [c-key]="'totalCostPrice'">
        <span>{{task.getTotalCostPrice().amount | currency : task.currencyUnit}}</span>
      </commentable>
    </div>
    <mat-divider></mat-divider>
    <div>
      <label>Selling Price</label>
      <commentable [c-entity]="task" [c-key]="'totalSellingPrice'">
        <span>{{task.getTotalSellingPrice().amount | currency : task.currencyUnit}}</span>
      </commentable>
    </div>
  </div>
</div>
<div *ngIf="scopeTabService.taskOpenStates[this.task.id]" class="task-info">
  <div class="info-header flex justify-between w-full">
    <h6 class="product-heading-bold">Task information</h6>
    <mat-icon class="material-symbols-rounded pointer orange" (click)="scopeTabService.taskOpenStates[this.task.id] = false">close</mat-icon>
  </div>
  <div>
    <h6 class="product-heading-bold">Duration</h6>
    <scope-ui-duration
      [has-setting]="'SCOPE__START_END_DATE'"
      [hidePeriod]="true"
      [opens]="'left'"
      [startDate]='task.startDate'
      (startDateChange)="updateStartDate($event)"
      [disabled]="!scopeApprovalFlowService.isScopeEditable(currentScope)"
      [(endDate)]='task.endDate'
      (endDateChange)="updateEndDate($event)" >
    </scope-ui-duration>
  </div>
  <div class="w-full">
    <h6 class="product-heading-bold">Description</h6>
    <description-editor
      [model]='task'
      [visible]="true"
      [showNoteBelow]="true"
      [status]="currentScope.status"
      [editable]="scopeApprovalFlowService.isScopeEditable(currentScope)"
      (onSaveDescription)="updateDescription($event)"
      (onSaveNote)="updateInternalNote($event)" >
    </description-editor>
  </div>
</div>
<!-- Accept/Reject Tasks -->
<div class="deliverable-review-container task-review gap-8"
     *ngIf="!scopeTabService.taskOpenStates[this.task.id] &&
          scopeApprovalFlowService.stateChecks.deliverableStatus.deliverablesInReview &&
          scopeApprovalFlowService.stateChecks.isCurrentVersion &&
          scopeApprovalFlowService.stateChecks.inReviewState.currentMember">
  <div class="deliverable-review-collaborators">
    <div class="approval-collaborators-list flex flex-col gap-1">
      <div class="flex items-center gap-0.5">
        <mat-icon class="material-symbols-rounded">people</mat-icon>
        <h6 class="product-heading-bold">Collaborators</h6>
      </div>
      <div class="flex flex-wrap gap-1">
        <div class="approval-collaborators-list-item" *ngFor="let collaborator of currentScope.collaborators | orderBy: 'id'; trackBy: trackById">
          <ng-container *ngIf="{ showAlt: false } as memberScope">
            <div *ngIf='memberScope.showAlt' class="user-avatar-placeholder">
              <p>{{ collaborator.user.firstName.charAt(0) }}</p>
            </div>
            <img *ngIf='!memberScope.showAlt' class="user-avatar-placeholder" src="{{cdnConfig!.userProfileUrl + '/' + collaborator.user.photoUri}}" alt="" (error)="memberScope.showAlt = true">
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="scopeApprovalFlowService.stateChecks.deliverableStatus.deliverablesInReview" class="deliverable-review-collaborators">
    <div class="approval-collaborators-list flex flex-col gap-1">
      <div class="flex items-center gap-0.5">
        <mat-icon class="material-symbols-rounded">eyeglasses</mat-icon>
        <h6 class="product-heading-bold">Reviewers</h6>
      </div>
      <div class="flex flex-wrap gap-1">
        <div class="approval-collaborators-list-item" *ngFor="let reviewer of task.reviewers | orderBy: 'id'; trackBy: trackById">
          <ng-container *ngIf="{ showAlt: false } as memberScope">
            <i *ngIf="reviewer.vote === 'ACCEPT'" class="material-symbols-rounded accepted">done</i>
            <i *ngIf="reviewer.vote === 'DECLINE'" class="material-symbols-rounded rejected">close</i>
            <div *ngIf='memberScope.showAlt' class="user-avatar-placeholder">
              <p>{{ reviewer.voter.firstName.charAt(0) }}</p>
            </div>
            <img *ngIf='!memberScope.showAlt' class="user-avatar-placeholder" src="{{cdnConfig!.userProfileUrl + '/' + reviewer.voter.photoUri}}" alt="" (error)="memberScope.showAlt = true">
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="deliverable-review-actions flex-col"
       *ngIf="scopeApprovalFlowService.stateChecks.deliverableStatus.deliverablesInReview && currentScope.status === 'SUBMITTED'">
    <ng-container *ngIf="this.scopeApprovalFlowService.findDeliverableReviewer(this.currentUser.id, this.task) as deliverableReviewer">
      <button
        [class.deliverable-accepted]="deliverableReviewer?.vote === 'ACCEPT'"
        [class.disabled]="!(deliverableReviewer?.vote === 'ACCEPT' || deliverableReviewer?.vote !== null)"
        [disabled]="deliverableReviewer?.vote === 'ACCEPT'"
        (click)="acceptDeliverable(this.task)">
        {{ deliverableReviewer?.vote === 'ACCEPT' ? 'Accepted' : 'Accept' }}
      </button>
      <button
        [class.deliverable-rejected]="deliverableReviewer?.vote === 'DECLINE'"
        [disabled]="deliverableReviewer?.vote === 'DECLINE' || deliverableReviewer?.vote === 'ACCEPT'"
        [class.disabled]="!(deliverableReviewer?.vote === 'DECLINE' || deliverableReviewer?.vote !== null)"
        (click)="rejectDeliverable(this.task)">
        {{ deliverableReviewer?.vote === 'DECLINE' ? 'Rejected' : 'Reject' }}
      </button>
    </ng-container>
  </div>
</div>
