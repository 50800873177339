import { CompanyManagementState } from '@app/features/company-management/models/company-management.state.model'
import { createSelector } from '@ngrx/store';
import * as fromFeature from '@app/features/company-management/store/reducers/index'

export const getCompanyManagementFromState = createSelector(
  fromFeature.getCompanyManagementState,
  (state: fromFeature.State) => state.companyManagement
);

const getOutputTemplates = (state: CompanyManagementState): any => state.outputTemplates

const getScopeTemplates = (state: CompanyManagementState): any => {
  return state.outputTemplates?.filter(o => o.templateScopeType)
    .sort((a, b) => a.name.localeCompare(b.name))
}

const getFolderTemplates = (state: CompanyManagementState): any => {
  return state.outputTemplates?.filter(o => !o.templateScopeType)
    .sort((a, b) => a.name.localeCompare(b.name))
}

const selectOutputTemplates = createSelector(getCompanyManagementFromState, getOutputTemplates)
const selectScopeTemplates = createSelector(getCompanyManagementFromState, getScopeTemplates)
const selectFolderTemplates = createSelector(getCompanyManagementFromState, getFolderTemplates)

export const CompanyManagementSelectors = {
  selectOutputTemplates,
  selectScopeTemplates,
  selectFolderTemplates
}
