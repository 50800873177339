import { Component, HostListener, Input } from "@angular/core";
import { SafePipe } from "@app/shared/pipe/safe.pipe";
import { EnvService } from "@envs/env-service";
import { ActivatedRoute, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import _ from "lodash";
import { AuthService } from '@core/service/auth.service'
import { environment } from '@envs/environment';

@Component({
  selector: 'group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
  providers: [ SafePipe ],

})
export class GroupComponent {
  @Input() id!: number;
  @Input() isSettings: boolean;
  baseJsUrl: string

  constructor(private envService: EnvService, private router: Router,
              private route: ActivatedRoute, private titleService: Title, private authService: AuthService) {}

  ngOnInit() {
    this.baseJsUrl = `${this.envService.baseJsUrl}/admin?tab=groups&host=${environment.baseHost}&childCompany=${this.authService.loggedInUser?.company.id}&group=${this.id}&isSettings=${!!this.isSettings}`
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    if (event.data.action === 'adminNavigate') {
      this.titleService.setTitle(_.startCase(_.camelCase(event.data.location)) + ' | Admin | Scope')
      this.router.navigate(
        [`/manage`],
        {
          queryParams: { 'tab': event.data.location }
        }
      )
    }
  }
}
