<div mat-dialog-title>
  <div class="modal__header review-modal">
    <div class="modal__title">
      <h1>{{ data.title }}</h1>
    </div>

    <div class="mt-2" [ngClass]="{ 'w-450': data.limitModalWidth }">
      <div class="review-modal-body">
        <p class="pb-2" *ngIf="data.stateChecks.deliverableReviewState.areAllDeliverablesReviewedByMe">
          All
          {{ data.currentScope.identity.identificationType === 'SCOPE_BY_ROLE' ? 'tasks' : ('deliverable' | langf) }}
          have been reviewed.
        </p>
        <p>
          Check your summary below before submitting. Please note you will be unable to make review amendments once
          submitted.
        </p>
      </div>
    </div>

    <button class="close-button" mat-dialog-close>
      <i class="material-symbols-rounded">close</i>
    </button>
  </div>
</div>

<mat-dialog-content aria-label="Generic modal">
  <!-- ERROR MESSAGE -->
  <div class="review-submit-modal">
    <div class="error-message" *ngIf="!data.stateChecks.deliverableReviewState.areAllDeliverablesReviewedByMe">
      You must review all
      {{ data.currentScope.identity.identificationType === 'SCOPE_BY_ROLE' ? 'tasks' : ('deliverable' | langf) }} in the
      {{ 'scope' | langf }} in order to submit your {{ 'scope' | langf }} review. Please review the un-reviewed
      {{ data.currentScope.identity.identificationType === 'SCOPE_BY_ROLE' ? 'task' : ('deliverable' | langf) }}(s) by
      clicking on a
      {{ data.currentScope.identity.identificationType === 'SCOPE_BY_ROLE' ? 'task' : ('deliverable' | langf) }} which
      displays a <i class="material-symbols-rounded">help</i>
      symbol.
    </div>

    <!-- DELIVERABLES TABLE -->
    <table class="deliverable-review-table">
      <thead>
        <tr>
          <th *ngFor="let col of data.deliverableTableData.cols" [style.width.%]="col.width">
            {{ col.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of data.deliverableTableData.rowData?.items; let rowIndex = index; trackBy: trackById"
          (click)="data.deliverableTableData.rowClickAction(item, $event)">
          <td *ngFor="let cell of data.deliverableTableData.rowData?.cells[rowIndex]">
            <div class='flex'>
              <i *ngIf="cell.icon" class="material-symbols-rounded item-icon">{{ cell.icon(item) }}</i>
              <i *ngIf="cell.iconVote" class="material-symbols-rounded vote {{cell.iconVote(item).classes}}">{{cell.iconVote(item).icon}}</i>
              {{ cell.value(item) }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- ALL DELIVERABLES REVIEWED ACTIONS -->
    <div *ngIf="data.stateChecks.deliverableReviewState.areAllDeliverablesReviewedByMe">
      <form #modalForm="ngForm">
        <mat-form-field class="form-group w-full pb-8" subscriptSizing="dynamic">
          <textarea
          class="resize-none"
          name="comment"
          matInput
          [(ngModel)]="comment"
          placeholder="Add a comment"></textarea>
        </mat-form-field>
      </form>

      <mat-dialog-actions>
        <div class="review-deliverable-actions">
          <button mat-button
            class="scope-ui-modal-flat-button"
            *ngIf="
              data.currentScope.reviewers.length > 1 &&
              data.currentScope.identity.team.findReviewerByUserId(data.currentUser.id).superVoter
            "
            (click)="openCompleteReviewModal(modalForm.value.comment, true)">
            Submit for all reviewers
          </button>
          <button mat-button class="scope-ui-modal-flat-button" (click)="openCompleteReviewModal(modalForm.value.comment, false)">Submit my review</button>
        </div>
      </mat-dialog-actions>
    </div>

    <!-- DELIVERABLES HAVE BEEN REVIEWED ACTIONS -->
    <mat-dialog-actions *ngIf="!data.stateChecks.deliverableReviewState.areAllDeliverablesReviewedByMe">
      <div class="review-deliverable-actions">
        <button mat-button class="scope-ui-modal-flat-button" (click)="openAcceptAllDeliverablesDialog()">
          Accept all unreviewed
          {{ data.currentScope.identity.identificationType === 'SCOPE_BY_ROLE' ? 'tasks' : ('deliverables' | langf) }}
        </button>

        <button mat-button class="scope-ui-modal-flat-button" (click)="openRejectDeliverableDialog()">
          Reject all unreviewed
          {{ data.currentScope.identity.identificationType === 'SCOPE_BY_ROLE' ? 'tasks' : ('deliverables' | langf) }}
        </button>
      </div>
    </mat-dialog-actions>
  </div>
</mat-dialog-content>
