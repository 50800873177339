import { createAction, props } from '@ngrx/store';
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model';

export enum CompanyManagementActionTypes {
  GET_OUTPUT_TEMPLATES = '[COMPANY MANAGEMENT CONFIG] Get Output Templates',
  GET_OUTPUT_TEMPLATES_SUCCESS = '[COMPANY MANAGEMENT CONFIG] Get Output Templates Success',
  GET_OUTPUT_TEMPLATES_FAILURE = '[COMPANY MANAGEMENT CONFIG] Get Output Templates Failure'
}

const getOutputTemplates = createAction(CompanyManagementActionTypes.GET_OUTPUT_TEMPLATES);

const getOutputTemplatesSuccess = createAction(
  CompanyManagementActionTypes.GET_OUTPUT_TEMPLATES_SUCCESS,
  props<{ outputTemplates: OutputTemplate[] }>()
);

const getOutputTemplatesFail = createAction(CompanyManagementActionTypes.GET_OUTPUT_TEMPLATES_FAILURE, props<{ error: Error }>());

export const CompanyManagementActions = {
  getOutputTemplates,
  getOutputTemplatesSuccess,
  getOutputTemplatesFail
}
