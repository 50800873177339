<scope-ui-tabs fontSize="13px" (onSelect)="onSelect($event)">
  <scope-ui-tab tabTitle="Company Details" routeLink="details" [active]='!route.snapshot.queryParamMap.get("tab") || route.snapshot.queryParamMap.get("tab") === "details"'
                requiredPrivilege="COMPANY_DETAILS__VIEW">
    <ng-template>
      <iframe class="app-frame" [src]="baseJsUrl | safe"></iframe>
    </ng-template>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="Accounts" routeLink="child-accounts" [active]='route.snapshot.queryParamMap.get("tab") === "child-accounts"'
                [hidden]="authorisedAsChildCompany() !== null || !(loggedInUser.company.parentCompany == null && parentChildService.childCompanies != null && parentChildService.childCompanies.length > 0)">
    <ng-template>
      <iframe class="app-frame" [src]="baseJsUrl | safe"></iframe>
    </ng-template>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="Integration Settings" routeLink="integration" [active]='route.snapshot.queryParamMap.get("tab") === "integration"'
                requiredPrivilege="COMPANY_TRAFFIC_SYSTEM__VIEW">
    <ng-template>
      <iframe class="app-frame" [src]="baseJsUrl | safe"></iframe>
    </ng-template>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="Application Settings" routeLink="application" [active]='route.snapshot.queryParamMap.get("tab") === "application"'
                requiredPrivilege="COMPANY_APPLICATION_SETTINGS__VIEW">
    <ng-template>
      <iframe class="app-frame" [src]="baseJsUrl | safe"></iframe>
    </ng-template>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="Data" routeLink="data" [active]='route.snapshot.queryParamMap.get("tab") === "data"'
                requiredPrivilege="COMPANY_PREDICTION_DATA__VIEW">
    <ng-template>
      <iframe class="app-frame" [src]="baseJsUrl | safe"></iframe>
    </ng-template>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="Office Locations" routeLink="office-locations" [active]='route.snapshot.queryParamMap.get("tab") === "office-locations"'
                requiredPrivilege="OFFICE_LOCATION__VIEW" [hidden]="authorisedAsChildCompany() !== null">
    <ng-template>
      <iframe class="app-frame" [src]="baseJsUrl | safe"></iframe>
    </ng-template>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="{{'scope_type' | lang }}" routeLink="scope-types" [active]='route.snapshot.queryParamMap.get("tab") === "scope-types"'
                requiredPrivilege="SCOPE_TYPE__VIEW" requiredSetting="SCOPE__TYPE_VIEW">
    <ng-template>
      <div class="manage-tab-wrapper">
        <scope-types></scope-types>
      </div>
    </ng-template>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="{{'second_party.p' | lang }}" routeLink="clients" [active]='route.snapshot.queryParamMap.get("tab") === "clients"'
                requiredPrivilege="SECOND_PARTY__VIEW">
    <ng-template>
      <div class="manage-tab-wrapper">
        <second-parties></second-parties>
      </div>
    </ng-template>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="Rate Cards" routeLink="rate cards" [active]='route.snapshot.queryParamMap.get("tab") === "rate cards"'
                requiredPrivilege="RATECARD__VIEW && SCOPE__VIEW_RATES">
    <ng-template>
      <div class="manage-tab-wrapper">
        <rate-cards></rate-cards>
      </div>
    </ng-template>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="Members" routeLink="members" [active]='route.snapshot.queryParamMap.get("tab") === "members"'
                requiredPrivilege="COMPANY_MEMBERS__VIEW">
    <ng-template>
      <iframe class="app-frame" [src]="baseJsUrl | safe"></iframe>
    </ng-template>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="Groups" routeLink="groups" [active]='route.snapshot.queryParamMap.get("tab") === "groups"'
                requiredPrivilege="COMPANY_GROUP__VIEW" [hidden]="authorisedAsChildCompany() !== null">
    <ng-template>
      <div class="manage-tab-wrapper">
        <groups></groups>
      </div>
    </ng-template>
  </scope-ui-tab>
  <scope-ui-tab tabTitle="{{'doc_template' | lang }} templates" routeLink="output" [active]='route.snapshot.queryParamMap.get("tab") === "output"'
                requiredPrivilege="OUTPUT_TEMPLATE__VIEW">
    <ng-template>
      <output-templates *ngIf='!route.snapshot.queryParamMap.get("id")' class="flex flex-col"></output-templates>
      <document-template
        *ngIf='route.snapshot.queryParamMap.get("type") === "document" && route.snapshot.queryParamMap.get("id")'
        class="flex flex-col h-full"></document-template>
      <spreadsheet-template
        *ngIf='route.snapshot.queryParamMap.get("type") === "spreadsheet" && route.snapshot.queryParamMap.get("id")'
        class="flex flex-col h-full"></spreadsheet-template>
    </ng-template>
  </scope-ui-tab>
</scope-ui-tabs>
