import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  CompanyManagementActions,
  CompanyManagementActionTypes,
} from '@app/features/company-management/store/actions/company-management.actions';
import { CompanyManagementService } from '@app/features/company-management/service/company-management.service';

@Injectable()
export class CompanyManagementEffects {
  constructor(private actions$: Actions, private manageService: CompanyManagementService) {}

  getOutputTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CompanyManagementActionTypes.GET_OUTPUT_TEMPLATES),
      switchMap(() => {
        return this.manageService.getAllWordTemplates().pipe(
          map((outputTemplates) => CompanyManagementActions.getOutputTemplatesSuccess({ outputTemplates: outputTemplates })),
          catchError((error) => {
            console.error(error)
            return of(CompanyManagementActions.getOutputTemplatesFail({ error }))
          })
        );
      })
    );
  })
}
