import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CompanyManagementService } from '@app/features/company-management/service/company-management.service';
import { User } from '@core/model/user.model';
import { AuthService } from '@core/service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '@core/service/language.service';
import { MatDialog } from '@angular/material/dialog';
import { ScopeUiModalComponent } from '@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component';
import { ModalConfig } from '@core/model/modal-config.model';
import {
  SNACKBAR_LENGTH_LONG,
  SnackbarEventType,
  SnackbarService,
} from '@shared/utils/snackbar.service';
import { trackById, untilDestroyed } from '@shared/utils/utils';
import { FormControl } from '@angular/forms';
import { CompanyGroup } from '@app/features/scope-overview/model/company-group.model';
import { CdnConfig } from '@core/model/cdn-config.model';
import { BootstrapSelectors } from '@core/store/selectors/bootstrap.selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupsComponent {
  private readonly destroy$;

  loggedInUser!: User
  loadingGroups = true
  groups: CompanyGroup[]
  cdnConfig: CdnConfig

  constructor(private authService: AuthService,
              private manageService: CompanyManagementService,
              private cdr: ChangeDetectorRef,
              private router: Router,
              public route: ActivatedRoute,
              private lang: LanguageService,
              private dialog: MatDialog,
              private snackbarService: SnackbarService,
              private store: Store) {
    this.destroy$ = untilDestroyed();
    this.loggedInUser = this.authService.loggedInUser
    this.retrieveGroups()
  }

  ngOnInit() {
    this.store.select(BootstrapSelectors.selectCdnConfig).pipe(this.destroy$()).subscribe((config: CdnConfig) => {
      this.cdnConfig = config!;
    });
  }

  retrieveGroups() {
    this.manageService.getCompanyGroups().subscribe({
      next: (result) => {
        this.loadingGroups = false
        this.groups = result
        this.cdr.detectChanges()
      }
    })
  }

  selectGroup(id) {
    this.router.navigate(
      [`/manage/group/${id}`],
      {
        relativeTo: this.route
      }
    )
  }

  showAddGroupDialog() {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Add Group`,
        `Enter a name of the group you'd like to create`,
        `Add Group`,
        undefined,
        (form: FormControl) => {
          let name = form.get('name').value
          this.manageService.createCompanyGroup({name: name, createdBy: this.loggedInUser}).subscribe({
            next: (result) => {
              this.groups.push(result)
              this.selectGroup(result.id)
              dialog.close()
            },
            error: (error) => {
              if (error.status == 402) {
                this.snackbarService.showSnackbar("Your {LANG:agency} has exceeded its group creation limit. Please upgrade.", SNACKBAR_LENGTH_LONG, SnackbarEventType.ERROR)
                dialog.close()
              } else if (error.status == 409) {
                form.get('name').setErrors({ conflict: true })
              }
            }
          })
        },
        undefined,
        [{ name: 'name', control: new FormControl(''), type: 'text', required: true,
          label: `Group name`, maxLength: 30 }],
        false,
        true,
        undefined,
        (form: FormControl) => !!form.get('name')?.value
      ),
    })
  }

  protected readonly trackById = trackById;
}
