import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@plugins/material/material.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { CompanyManagementRoutingModule } from './company-management-routing.module';
import { ManageComponent } from './components/manage/manage.component';
import { CompanyManagementNgrxModule } from './company-management-ngrx.module';
import { ScopeUiCardMasterComponent } from '@app/shared/components/ui-components/scope-ui-card-master/scope-ui-card-master.component';
import { ScopeUiAutocompleteComponent } from '@app/shared/components/ui-components/scope-ui-autocomplete/scope-ui-autocomplete.component';
import {
  AccountSetupComponent
} from "@app/features/company-management/components/account-setup/account-setup.component";
import { InvitationSetupComponent } from './components/invitation-setup/invitation-setup.component';
import { ScopeUiTabComponent } from '@shared/components/ui-components/scope-ui-tab/scope-ui-tab.component';
import { ScopeUiTabsComponent } from '@shared/components/ui-components/scope-ui-tabs/scope-ui-tabs.component';
import { RatecardComponent } from '@app/features/company-management/components/ratecard/ratecard.component';
import {
  OutputTemplatesComponent
} from '@app/features/company-management/components/output-templates/output-templates.component';
import {
  DocumentTemplateComponent
} from '@app/features/company-management/components/document-template/document-template.component';
import { OutputEditorComponent } from '@shared/components/output-editor/output-editor.component';
import {
  SpreadsheetTemplateComponent
} from '@app/features/company-management/components/spreadsheet-template/spreadsheet-template.component';
import { ScopeUiInputComponent } from '@shared/components/ui-components/scope-ui-input/scope-ui-input.component';
import { ScopeUiOptionsMenuComponent } from '@shared/components/scope-ui-options-menu/scope-ui-options-menu.component';
import {
  SecondPartiesComponent
} from '@app/features/company-management/components/second-parties/second-parties.component';
import { SecondPartyComponent } from '@app/features/company-management/components/second-party/second-party.component';
import { RateCardsComponent } from '@app/features/company-management/components/rate-cards/rate-cards.component';
import { GroupsComponent } from '@app/features/company-management/components/groups/groups.component';
import { GroupComponent } from '@app/features/company-management/components/group/group.component';
import { ScopeTypesComponent } from '@app/features/company-management/components/scope-types/scope-types.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    CompanyManagementRoutingModule,
    CompanyManagementNgrxModule,
    ScopeUiCardMasterComponent,
    ScopeUiAutocompleteComponent,
    ScopeUiTabComponent,
    ScopeUiTabsComponent,
    OutputEditorComponent,
    ScopeUiInputComponent,
    ScopeUiOptionsMenuComponent,
  ],
  declarations: [ManageComponent, AccountSetupComponent, InvitationSetupComponent, RatecardComponent,
    SecondPartiesComponent, SecondPartyComponent, RateCardsComponent, OutputTemplatesComponent, GroupsComponent,
    GroupComponent, DocumentTemplateComponent, SpreadsheetTemplateComponent, ScopeTypesComponent],
  providers: [],
  exports: [RouterModule],
})
export class CompanyManagementModule {}
