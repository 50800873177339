import { Observable } from 'rxjs';

export interface UploadModalConfig {
  title: string
  body?: string
  type: UploadType
  submitFn: (file: File) => Observable<any>
  successFn: (result: any) => void
  showRemoveOption?: boolean
  removeOptionText?: string
  removeFn?: () => void
  showBody?: boolean
}

export enum UploadType {
  WORD = "word",
  EXCEL = "excel",
  CLIENTS = "clients",
  LOCATION = "location",
  CONTACT = "contact",
  BRAND = "brand",
  RATECARD = "ratecard",
  DELIVERABLE = "deliverable"
}
