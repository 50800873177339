import {Component, ContentChildren, EventEmitter, Input, Output, QueryList} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScopeUiTabComponent } from '../scope-ui-tab/scope-ui-tab.component';
import { SharedModule } from '@app/shared/shared.module';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'scope-ui-tabs',
  standalone: true,
  imports: [CommonModule, SharedModule, ScopeUiTabComponent, RouterLink],
  templateUrl: './scope-ui-tabs.component.html',
  styleUrls: ['./scope-ui-tabs.component.scss'],
})
export class ScopeUiTabsComponent {
  @Input() fontSize!: string;
  @Output() onSelect: EventEmitter<string>;
  @Output() onInit: EventEmitter<string>;

  @ContentChildren(ScopeUiTabComponent) tabs!: QueryList<ScopeUiTabComponent>;

  constructor() {
    this.onSelect = new EventEmitter<string>();
    this.onInit = new EventEmitter<string>();
  }

  ngAfterContentInit() {
    const activeTabs = this.tabs.filter((tab) => tab.active);

    if (activeTabs.length === 0) {
      this.tabs.first.active = true;
      this.onInit ? this.onInit.emit(this.tabs.first.tabTitle) : this.onSelect.emit(this.tabs.first.tabTitle)
    } else {
      this.onInit ? this.onInit.emit(activeTabs[0].tabTitle) : this.onSelect.emit(activeTabs[0].tabTitle)
    }
  }

  selectTab(tab: ScopeUiTabComponent) {
    if (tab && tab.active) return;
    this.tabs.toArray().forEach((tab) => (tab.active = false));
    if (tab) {
      tab.active = true;
      this.onSelect.emit(tab.routeLink || tab.tabTitle);
    }
  }
}
