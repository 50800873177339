<div>
  <div mat-dialog-title>
    <div class="modal__header">
      <div class="modal__title">
        <h1>{{ data.title }}</h1>
      </div>
      <div class="modal-text mt-2">
      </div>
      <button class="close-button" mat-dialog-close>
        <i class="material-symbols-rounded">close</i>
      </button>
    </div>
  </div>

  <mat-dialog-content aria-label="Generic modal">
    <div class="pt-6">
      <scope-ui-input class="resize-none roles-search"
                      [hasPlaceholder]="true"
                      [inputLabel]="'Name*'"
                      [inputClass]="'mx-9 icon-field'"
                      (onInputChange)="onNameChange($event)" />
      <div class="mx-8 mb-5 mt-1">
        <div class="desc-menu">
          <scope-ui-input
            class="desc-input"
            [inputLabel]="'Description*'"
            [initialValue]="newThirdPartyCost.description"
            [isTextarea]="true"
            (onInputChange)="newThirdPartyCost.description = $event"/>
        </div>

        <scope-ui-dropdown
          class="roles-search"
          [controlLabel]="'Formula'"
          [displayFn]="displayName"
          [dropdownOptions]="dropdownOptions"
          [isStandardDropdown]="true"
          (onSelectionChange)="onSelection($event)"
        >
        </scope-ui-dropdown>
        <div class="flex gap-9 price-menu mt-3">
          <scope-ui-input class="price-input"
                          [hasPlaceholder]="true"
                          [type]="'number'"
                          [control]="costControl"
                          [inputLabel]="'Unit cost*'"
                          [minimum]="0"
                          [preventPaste]="true"
                          [inputClass]="'mx-10 icon-field'"
                          (onInputChange)="onCostChange($event)" />
          <scope-ui-input class="price-input"
                          [hasPlaceholder]="true"
                          [type]="'number'"
                          [control]="markupControl"
                          [inputLabel]="'Mark up (%)*'"
                          [minimum]="0"
                          [preventPaste]="true"
                          [inputClass]="'mx-10 icon-field'"
                          (onInputChange)="onMarkUpChange()" />
          <scope-ui-input class="price-input"
                          [hasPlaceholder]="true"
                          [type]="'number'"
                          [control]="priceControl"
                          [inputLabel]="'Selling price*'"
                          [minimum]="0"
                          [preventPaste]="true"
                          [inputClass]="'mx-10 icon-field'"
                          (onInputChange)="onPriceChange()" />
        </div>
      </div>


    </div>
    <div class="flex justify-between p-4">
      <div class="role-actions">
        <button mat-button mat-dialog-close>Cancel</button>
        <button class="main-action-button ml-4" [ngClass]="{'disabled': disableSubmit()}" mat-button [disabled]="disableSubmit()" (click)="submit()">
          Create
        </button>
      </div>
    </div>
  </mat-dialog-content>
</div>
