<div class="modal-container">
  <div mat-dialog-title>
    <div class="modal__header">
      <div class="modal__title">
        <h1>{{ data.title }}</h1>
      </div>

      <div class="mt-2 w-450" *ngIf="data.showBody">
        <span>{{data.title}} using Excel file</span>

        <div class="upload-help">
          <span>Download and use the following <a (click)="getTemplateExampleUrl()" target="_self">Generate Template</a>. Refer to
          <a href="{{getInstructionUrl()}}" target="_blank" style="outline: none;">Instruction Manual</a> for further information.</span>
        </div>
      </div>

      <button class="close-button" mat-dialog-close>
        <mat-icon class="material-symbols-rounded">close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content>
    <loader-modal *ngIf="verifyingOrImportingLoader" [overlay]="true" [modal]="false"></loader-modal>
    <div *ngIf="!verifyingOrImportingLoader" class="modal__body text-center">
      <span class="modal-body-text" *ngIf="errors.length > 0 || !verifiedFile">{{ data.body }}</span>
      <div class="flex flex-col items-center" *ngIf="errors.length > 0 || !verifiedFile">
        <div class="mb-2">
          <mat-icon class="material-symbols-rounded pointer" *ngIf="fileName" (click)="downloadConflictedFile()">file_download</mat-icon>
          <mat-icon class="material-symbols-rounded" *ngIf="!fileName">insert_drive_file</mat-icon>
          <p class="file-name" *ngIf="fileName">{{fileName}}</p>
        </div>
        <input
          hidden
          type="file"
          accept="{{data.type === 'word' ? '.docx' : '.xlsx'}}"
          #uploader
          (change)="uploadFile($event)"
        />
        <button class="browse-btn" (click)="uploader.click()">
          Browse
        </button>
      </div>
      <div *ngIf="errors.length > 0">
        <ul class="error-upload">
          <li *ngFor="let error of errors">{{errorText[error.errorType]}}{{error.additionalText ?
            ': ' + error.additionalText : ''}}. {{error.line ? 'Line: ' + error.line : ''}}
          </li>
        </ul>
        <ul *ngIf="showErrorList">
          <li><span style="color: #FF0000">Red</span> - Required</li>
          <li><span style="color: #0000FF">Blue</span> - Not Found</li>
          <li><span style="color: #00FF00">Green</span> - Duplicate</li>
          <li><span style="color: #A52A2A">Brown</span> - Not Valid</li>
        </ul>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <ng-container *ngIf="data.type === UploadType.EXCEL || (errors.length == 0 && verifiedFile)">
      <h4 *ngIf="data.type !== UploadType.EXCEL" style="color: #008d00">
        File is verified. <br> Please, proceed with the upload.
      </h4>

      <button
        mat-button
        class="confirm scope-ui-modal-flat-button"
        (click)="submit()">Upload
      </button>
    </ng-container>

    <button
      *ngIf="data.type !== UploadType.EXCEL && (verifiedFile == null || errors.length > 0)"
      mat-button
      class="confirm scope-ui-modal-flat-button"
      (click)="validateExcelFile()">Validate
    </button>
    <p *ngIf="data.showRemoveOption" class="mt-4">
      <b>{{data.removeOptionText}}</b>
      <a class="orange" (click)="data.removeFn()"> Click here</a>
    </p>
  </mat-dialog-actions>
</div>
