<div class="flex d-flex-justify-between pt-6 px-10">
  <h3 class="product-heading-bold">{{'second_party.p' | lang}}</h3>
  <div class="flex gap-1">
    <scope-ui-input
      class="mr-2"
      [hasPlaceholder]="false"
      [inputPlaceholder]="''"
      [icon]="'search'"
      [cancelIcon]="'cancel'"
      [inputLabel]="'Search'"
      [inputClass]="'search-field'"
      (onCancel)="searchText = ''; filterSecondParties()"
      (onEnter)="searchText = $event; filterSecondParties()" />
    <button [has-privilege]="'SECOND_PARTY__CREATE'" (click)="showAddSecondPartyDialog()"
            mat-flat-button>
      <mat-icon class="material-symbols-rounded">add</mat-icon> Add {{'second_party' | lang}}
    </button>
    <button
      class="scope-actions-button"
      (click)="$event.stopPropagation()"
      [matMenuTriggerFor]="optionsMenu">
      <mat-icon class="material-symbols-rounded">more_vert</mat-icon>
    </button>
    <mat-menu
      #optionsMenu="matMenu"
      backdropClass="scope-options-menu">
      <ng-container *ngFor="let option of menuOptions">
        <button
          (click)="option.click()"
          *ngIf="option.isVisible ? option.isVisible() : true"
          mat-menu-item
          class="scope-option">
          <mat-icon class="material-symbols-rounded orange">{{ option.icon }}</mat-icon>
          <span>{{ option.name }}</span>
        </button>
      </ng-container>
    </mat-menu>
  </div>
</div>
<div class="card-wrapper p-10" [hidden]="!loadingSecondParties">
  <div class="card-skeleton" *ngFor=" let x of [].constructor(16)"></div>
</div>
<div class="card-wrapper p-10">
  <scope-ui-card-master
    *ngFor="let secondParty of filteredSecondParties | orderBy: 'name': 'asc' : false; trackBy: trackById"
    (click)="selectSecondParty(secondParty.id)"
    [actionButtons]="[{ icon: 'delete', name: 'Delete', eventId: 'Delete', hasPrivilege: 'SECOND_PARTY__DELETE',
      hasPrivilegeEntity: secondParty }]"
    [hasDropDown]="!secondParty.groupShared && loggedInUser.hasPrivilege('RATECARD__VIEW')"
    [isStandardDropdown]="false"
    [dropdownOptions]="rateCards"
    [dropdownSelectedValue]="secondParty.rateCardIdentities"
    [dropdownValueFn]="getRateCardIdentity"
    [controlLabel]="'Rate Cards'"
    [hasStatus]="secondParty.trafficImported"
    [hasButtons]="!secondParty.groupShared"
    [cardTitle]="secondParty.name"
    [status]="'TRAFFICKED'"
    [hasIcon]="secondParty.trafficImported"
    [statusIcon]="'swap_vert'"
    [statusIconClass]="'trafficked'"
    [hasMetadata]="secondParty.groupShared || secondParty.isOwnedByParentOf(loggedInUser.company)"
    [metaData]="secondParty.isOwnedByParentOf(loggedInUser.company) ? 'Inherited from parent company' : 'Shared to user as a group member in company ' + secondParty.company.name"
    [showDropdownToggle]="!secondParty.groupShared"
    [dropdownMinimum]="1"
    dropdownToggleText="rate cards ({{secondParty.rateCardIdentities.length}})"
    (buttonClicked)="showDeleteSecondPartyModal(secondParty)"
    (onSelectionChange)="changeAssociatedSecondPartyRateCards(secondParty, $event)"/>
</div>
