import { Injectable } from '@angular/core';
import { environment } from '@envs/environment';
import { HttpClient } from '@angular/common/http';
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model';
import { map, Observable } from 'rxjs';
import { plainToInstance } from 'class-transformer';
import {
  ParentChildOutputWhitelistInfo
} from '@app/features/scope-overview/components/change-scope-output-modal/change-scope-output-modal.component';
import { SpreadsheetTemplate } from '@app/features/scope-overview/model/spreadsheet-template.model';
import { SecondParty } from '@core/model/second-party.model';
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model';
import { RatecardStructureVersion } from '@app/features/scope-overview/model/ratecard-structure.model';
import { CostPlus } from '@app/features/scope-overview/model/cost-plus.model';
import { CompanyGroup } from '@app/features/scope-overview/model/company-group.model';
import { ScopeType } from '@app/features/scope-overview/model/scope-type.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyManagementService {
  private readonly BASE_API_URL: string = environment.scopeApiUrl;

  public currencies = ['GBP','EUR','AUD','USD','CAD','SGD','CZK','SEK','DKK','PLN','NOK','ZAR','AED','CHF','JPY','THB',
    'KES', 'MYR', 'INR', 'CNY', 'HKD', 'BRL', 'LVL', 'LTL', 'HUF', 'VND', 'ISK', 'TWD', 'MXN', 'MAD', 'NZD', 'SAR',
    'QAR','IDR','KHR','ILS','MDL','NGN','ARS','COP','CLP','UGX','MUR']

  constructor(private http: HttpClient) {
  }

  getParentOutputTemplatesWhitelist(companyId: number) {
    return this.http
      .get<ParentChildOutputWhitelistInfo>(`${this.BASE_API_URL}company/child-companies/${companyId}/whitelist/output`)
      .pipe(map((api) => plainToInstance(ParentChildOutputWhitelistInfo, api)))
  }

  getAllWordTemplates() {
    return this.http.get<OutputTemplate[]>(`${this.BASE_API_URL}company/custom-output-template?includeFields=false`)
      .pipe(map((api) => plainToInstance(OutputTemplate, api)))
  }

  getScopeWordTemplates() {
    return this.http.get<OutputTemplate[]>(`${this.BASE_API_URL}company/custom-output-template/scope?includeFields=false`)
      .pipe(map((api) => plainToInstance(OutputTemplate, api)))
  }

  getAllXlsxTemplates() {
    return this.http.get<SpreadsheetTemplate[]>(`${this.BASE_API_URL}xlsx-templates`)
      .pipe(map((api) => plainToInstance(SpreadsheetTemplate, api)))
  }

  getWordTemplate(templateId: number) {
    return this.http.get<OutputTemplate>(`${this.BASE_API_URL}company/custom-output-template/${templateId}`)
      .pipe(map((api) => plainToInstance(OutputTemplate, api)))
  }

  getXlsxTemplate(templateId: number) {
    return this.http.get<SpreadsheetTemplate>(`${this.BASE_API_URL}xlsx-templates/${templateId}`)
      .pipe(map((api) => plainToInstance(SpreadsheetTemplate, api)))
  }

  updateCompanyCustomOutputTemplate(template: OutputTemplate) {
    return this.http
      .put(`${this.BASE_API_URL}company/custom-output-template-manage`, template)
      .pipe(map((api) => plainToInstance(OutputTemplate, api)));
  }

  updateXlsxOutputTemplate(template: SpreadsheetTemplate) {
    return this.http.put(`${this.BASE_API_URL}xlsx-templates`, template);
  }

  deleteXlsxOutputTemplate(templateId: number) {
    return this.http.delete(`${this.BASE_API_URL}xlsx-templates/${templateId}`);
  }

  downloadExcelTemplate(templateId: number) {
    return this.http.get(`${this.BASE_API_URL}xlsx-templates/download/${templateId}`, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  uploadExcelTemplate(file: File, templateId: number) {
    let formData = new FormData();
    formData.append('image', file);
    return this.http.post(`${this.BASE_API_URL}xlsx-templates/upload/${templateId}`, formData)
      .pipe(map((api) => plainToInstance(SpreadsheetTemplate, api)))
  }

  deleteCompanyCustomOutputTemplate(templateId: number) {
    return this.http.delete(`${this.BASE_API_URL}company/custom-output-template/${templateId}`);
  }

  exportScopeOutputToDocx(templateId: number, downloadFileName?: string) {
    return this.http.get(`${this.BASE_API_URL}scope/${templateId}/export/download${downloadFileName ? "?downloadFileName=" + downloadFileName : ''}`, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  exportFolderOutputToDocx(templateId: number) {
    return this.http.get(`${this.BASE_API_URL}sow/${templateId}/export/preview`, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  duplicateCompanyCustomOutputTemplate(templateId: number, newName: string) {
    return this.http.post(`${this.BASE_API_URL}company/custom-output-template/${templateId}`, { newName })
  }

  uploadCustomTemplateDocxBaseTemplate(templateId: number, file: File) {
    let formData = new FormData();
    formData.append('image', file);
    return this.http.post(`${this.BASE_API_URL}company/custom-output-template/${templateId}/base-docx-template`, formData)
      .pipe(map((api) => plainToInstance(OutputTemplate, api)))
  }

  removeCustomTemplateDocxBaseTemplate(templateId: number) {
    return this.http.delete(`${this.BASE_API_URL}company/custom-output-template/${templateId}/base-docx-template`)
      .pipe(map((api) => plainToInstance(OutputTemplate, api)))
  }

  previewExport(templateId: number) {
    return this.http.get(`${this.BASE_API_URL}scope/${templateId}/export/preview`, {
      observe: 'response',
    }).pipe(
      map((response) => {
        let contentDisposition = response.headers.get('content-disposition');
        let name = contentDisposition.split('name="')[1].split('"')[0];
        let filename = contentDisposition.split('filename="')[1].split('"')[0];
        return { data: response.body, name: name, filename: filename };
      })
    )
  }

  createCompanyCustomOutputTemplate(template) {
    return this.http.post(`${this.BASE_API_URL}company/custom-output-template`, template)
      .pipe(map((api) => plainToInstance(OutputTemplate, api)))
  }

  createXlsxOutputTemplate(template) {
    return this.http.post(`${this.BASE_API_URL}xlsx-templates`, template)
      .pipe(map((api) => plainToInstance(SpreadsheetTemplate, api)))
  }

  getOwnSecondPartiesAndGroupShared() {
    return this.http.get<SecondParty[]>(`${this.BASE_API_URL}second-party?includeGroupShared=true`)
      .pipe(map((api) => plainToInstance(SecondParty, api)))
  }

  addSecondParty(secondParty) {
    return this.http.post(`${this.BASE_API_URL}second-party`, secondParty)
      .pipe(map((api) => plainToInstance(SecondParty, api)))
  }

  getAllRateCards() {
    return this.http.get<RatecardVersion[]>(`${this.BASE_API_URL}company/ratecard`)
      .pipe(map((api) => plainToInstance(RatecardVersion, api)))
  }

  deleteSecondParty(id: number) {
    return this.http.delete(`${this.BASE_API_URL}second-party/${id}`)
  }

  deleteRateCard(id: number) {
    return this.http.delete(`${this.BASE_API_URL}company/ratecard/${id}`)
  }

  getRateCardStructures(): Observable<RatecardStructureVersion[]> {
    return this.http.get(`${this.BASE_API_URL}ratecard-structure`)
      .pipe(map((api: { content: RatecardStructureVersion[] }) => plainToInstance(RatecardStructureVersion, api.content)))
  }

  getAllCostPluses() {
    return this.http.get<CostPlus[]>(`${this.BASE_API_URL}company/cost-plus`)
      .pipe(map((api) => plainToInstance(CostPlus, api)))
  }

  createRatecardFromStructure(rateCard) {
    return this.http.post(`${this.BASE_API_URL}company/ratecard/from-structure`, rateCard)
      .pipe(map((api) => plainToInstance(RatecardVersion, api)))
  }

  createCompanyGroup(group) {
    return this.http.post(`${this.BASE_API_URL}company/group`, group)
      .pipe(map((api) => plainToInstance(CompanyGroup, api)))
  }

  getCompanyGroups() {
    return this.http.get<CompanyGroup[]>(`${this.BASE_API_URL}company/group`)
      .pipe(map((api) => plainToInstance(CompanyGroup, api)))
  }

  getScopeTypes() {
    return this.http.get<CompanyGroup[]>(`${this.BASE_API_URL}scope-type`)
      .pipe(map((api) => plainToInstance(ScopeType, api)))
  }

  createScopeType(scopeType) {
    return this.http.post(`${this.BASE_API_URL}scope-type`, scopeType)
      .pipe(map((api) => plainToInstance(ScopeType, api)))
  }

  updateScopeType(scopeType) {
    return this.http.put(`${this.BASE_API_URL}scope-type/${scopeType.id}`, scopeType)
      .pipe(map((api) => plainToInstance(ScopeType, api)))
  }

  deleteScopeType(id: number) {
    return this.http.delete(`${this.BASE_API_URL}scope-type/${id}`)
  }

  getRateCardMetaStructure() {
    return this.http.get(`${this.BASE_API_URL}company/ratecard/meta-structure`)
  }

  verify(file, type) {
    let formData = new FormData();
    formData.append('file', file);
    return this.http.post(`${this.BASE_API_URL}company/import/verify/${type}`, formData, {
      responseType: 'arraybuffer'
    })
  }

  updateSecondPartyRateCards(secondParty: SecondParty) {
    return this.http.put(`${this.BASE_API_URL}second-party/${secondParty.id}/ratecards/`, secondParty.rateCardIdentities)
  }

  import(file, type) {
    let formData = new FormData();
    formData.append('image', file);
    return this.http.post(`${this.BASE_API_URL}company/import/${type}`, formData)
  }

  syncTrafficImportsSecondParty(syncName: boolean) {
    return this.syncTrafficImports({ secondpartySync: true, syncName: syncName })
  }

  syncTrafficImportsRateCard(syncName: boolean) {
    return this.syncTrafficImports({ ratecardSync: true, syncName: syncName })
  }

  syncTrafficImports(config) {
    let url = `${this.BASE_API_URL}company/traffic-system/force-sync?`;
    if (config.ratecardSync){
      url += "ratecardSync=true&"
    }

    if (config.secondpartySync){
      url += "secondpartySync=true&"
    }

    if (config.syncName){
      url += "syncName=true"
    }

    return this.http.post(url, null)
  }

  renderTrafficSystemErrorOnSync(type) {
    return this.http.get(`${this.BASE_API_URL}company/traffic-system/force-sync/last-imported-data/${type}`)
  }

  getTrafficSystemSecondParties(pageNumber) {
    return this.http.get(`${this.BASE_API_URL}company/traffic-system/second-parties?pageNumber=${pageNumber}`)
  }

  getTrafficSystemRateCards(pageNumber) {
    return this.http.get(`${this.BASE_API_URL}company/traffic-system/ratecard?pageNumber=${pageNumber}`)
  }

  renderTrafficSystemSecondPartiesError(pageNumber) {
    return this.http.get(`${this.BASE_API_URL}company/traffic-system/second-parties/last-imported-data/page-number/${pageNumber}`)
  }

  renderTrafficSystemRateCardError(pageNumber) {
    return this.http.get(`${this.BASE_API_URL}company/traffic-system/ratecard/last-imported-data/page-number/${pageNumber}`)
  }

  importTrafficSystemSecondParties(selectedIdsToNewNameJson) {
    return this.http.post<SecondParty[]>(`${this.BASE_API_URL}company/traffic-system/second-parties/import`,  selectedIdsToNewNameJson)
      .pipe(map((api) => plainToInstance(SecondParty, api)))
  }

  importTrafficSystemRateCards(selectedIdsToNewNameJson) {
    return this.http.post<RatecardVersion[]>(`${this.BASE_API_URL}company/traffic-system/ratecard/import`,  { selectedRatecardIdsToName: selectedIdsToNewNameJson })
      .pipe(map((api) => plainToInstance(RatecardVersion, api)))
  }

  duplicateRateCard(ratecardId, opt) {
    return this.http.post<RatecardVersion>(`${this.BASE_API_URL}company/ratecard/${ratecardId}/duplicate`, opt)
      .pipe(map((api) => plainToInstance(RatecardVersion, api)))
  }

  getTrafficSystemRatecardsByExternalId(externalIds) {
    return this.http.get(`${this.BASE_API_URL}company/traffic-system/ratecard/${(externalIds instanceof Array ? externalIds.join(',') : externalIds)}`)
  }

  requestExportOfRatecard(rateCard) {
    let rateCardIdentityId = rateCard?.rateCardIdentity?.id || "";
    let rateCardVersion = rateCard?.version || "";
    return this.http.post(`${this.BASE_API_URL}company/ratecard/export?rateCardIdentityId=${rateCardIdentityId}&version=${rateCardVersion}`, null);
  }
}
