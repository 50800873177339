<div class="modal-container change-output-modal">
  <div mat-dialog-title>
    <div class="modal__header review-modal">
      <div class="modal__title">
        <h1>Change {{'scope' | langf }} {{'output' | langf}}</h1>
      </div>

      <div class="mt-2" [ngClass]="{ 'w-450': data.limitModalWidth }">
        <div class="modal-body">
          <p>
            Select which company template you want to use for {{'scope' | langf }} output.
            <span class="extra-text">(It will override all previous changes you made for current {{'scope|l' | lang }})</span>:
          </p>
        </div>
      </div>

      <button class="close-button" mat-dialog-close>
        <i class="material-symbols-rounded">close</i>
      </button>
    </div>
  </div>

  <mat-dialog-content aria-label="Generic modal">
    <div class="modal__body">
      <div class="submit-modal" *ngIf="listOutputTemplates.length > 0">
        <div class="output-image-container text-center" *ngFor="let temp of listOutputTemplates; trackBy: trackById">
          <div class="title">
            <span matTooltip="{{temp.name}}">{{temp.name}}</span>
          </div>
          <img *ngIf="temp.thumbnailUri" class="template-preview" height="260" width="271" ngSrc="{{cdnConfig.getStaticMediaUrl(temp.thumbnailUri)}}" alt="{{temp.name}}">
          <div *ngIf="!temp.thumbnailUri" class="template-preview">BLANK</div>
          <p class="d-flex d-flex-align-center d-flex-justify-center cursor-pointer font-bold orange" (click)="changeMasterTemplate(temp.id, false)">
            Use this template
            <i *ngIf="temp.inherited" style="margin-left: 10px" title="Inherited from parent company." class="material-icons orange">upload_file</i>
          </p>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>
