<div mat-dialog-title>
  <div class="modal__header">
    <div class="modal__title">
      <h1 *ngIf="isFixedFee">Add a new Fixed Fee</h1>
      <h1 *ngIf="!isFixedFee">Add {{'deliverable' | lang}}</h1>
    </div>

    <button class="close-button" mat-dialog-close>
      <mat-icon class="material-symbols-rounded">close</mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content aria-label="Generic modal" [class.modal-992]='showMapping'>
  <div class="pt-6 w-full">
    <form *ngIf="!nextModalStep" [formGroup]="modalForm">
      <div class="flex flex-col" *ngIf='!isNewCustomDeliverable'>
        <scope-ui-autocomplete [label]="isFixedFee ? 'Search Fixed Fee' : 'Search name'" [options]="availableDeliverables$ | async" [filterByAction]='true' (onSearch)='searchDeliverables($event)' [optionClass]="'deliverable-type'" [hasIcon]="true" [iconName]="'search'" [multiselect]="false" (onSelectionChange)="onAutocompleteSelect($event)" />

        <ng-container *ngIf="selection">
          <mat-form-field class="form-group w-full pt-6">
            <mat-label>{{'deliverable' | lang}} Name</mat-label>
            <input class="resize-none" matInput formControlName="name" placeholder="Enter new name">
          </mat-form-field>

          <mat-form-field>
            <mat-label>Budget</mat-label>
            <input name="budget" mask="separator.2" thousandSeparator="," prefix="{{symbol}}" matInput formControlName="budget" />
          </mat-form-field>

          <ng-container *ngIf="currentScope.identity.secondParty.trafficImported && currentScope.identity.rateCard.rateCardIdentity.trafficImported">
            <div class="flex-row pb-6" *ngFor="let field of trafficFields">
              <ng-container *ngIf="!field.url">
                <scope-ui-input class="resize-none"
                                [hasPlaceholder]="true"
                                [inputPlaceholder]="field.name"
                                [inputLabel]="field.name"
                                (onInputChange)="updateTrafficSystemMetadataField(field.uuid, $event)" />
              </ng-container>
              <ng-container *ngIf="field.url">
                <scope-ui-autocomplete
                  [options]="(traffickingService.trafficSystemEntityMetadataSearch$[field.uuid] | async)!"
                  [label]="field.name"
                  [filterByAction]="true"
                  [optionFn]="traffickingService.trafficFieldOption"
                  (onSearch)="traffickingService.onSearchTrafficField($event, field.uuid, currentScope.identity.id)"
                  (onSelectionChange)="selectTrafficField($event, field.uuid)"/>
              </ng-container>
            </div>
          </ng-container>

          <scope-ui-duration
            class="pt-6 block"
            [has-setting]="'SCOPE__START_END_DATE'"
            [(startDate)]='newDeliverable.startDate'
            [(endDate)]='newDeliverable.endDate' >
          </scope-ui-duration>
        </ng-container>
      </div>

      <ng-container *ngIf="isNewCustomDeliverable">
        <mat-form-field class="form-group w-full">
          <mat-label>{{'deliverable' | lang}} Name</mat-label>
          <input class="resize-none" matInput formControlName="name" placeholder="Enter new name">
        </mat-form-field>

        <scope-ui-dropdown
          class="flex-1" style="max-width: 500px"
          [controlLabel]="'Discipline'"
          [element]="discipline"
          [dropdownOptions]="disciplines"
          [isStandardDropdown]="true"
          (onSelectionChange)="onSelectDiscipline($event)" />

        <mat-form-field class="pt-6">
          <mat-label>Budget</mat-label>
          <input name="budget" mask="separator.2" thousandSeparator="," prefix="{{symbol}}" matInput formControlName="budget" />
        </mat-form-field>

        <div class="form-group w-full pb-4 pt-3">
          <description-editor
            [model]='{}'
            [(visible)]="editDescription"
            [hideNote]='true'
            [status]="'DRAFT'"
            (onSaveDescription)="description = $event" >
          </description-editor>
        </div>

        <scope-ui-dropdown *ngIf="!isFixedFee"
          class="flex-1" style="max-width: 500px"
          [controlLabel]="'Item type'"
          [element]="itemType"
          [dropdownOptions]="libraryItemTypes"
          [isStandardDropdown]="true"
          (onSelectionChange)="onSelectType($event)" />

        <scope-ui-dropdown
          class="flex-1" style="max-width: 500px"
          [has-setting]="'SCOPE__DELIVERABLE__OWNER'"
          [controlLabel]="'Owner'"
          [element]="owner"
          [selectedValue]="owner"
          [displayParam]="'fullName'"
          [dropdownOptions]="availableOwners$ | async"
          [isStandardDropdown]="true"
          (onSelectionChange)="onSelectOwner($event)" />

        <ng-container *ngIf="currentScope.identity.secondParty.trafficImported && currentScope.identity.rateCard.rateCardIdentity.trafficImported">
          <div class="flex-row pt-6" *ngFor="let field of trafficFields">
            <ng-container *ngIf="!field.url">
              <scope-ui-input class="resize-none"
                              [hasPlaceholder]="true"
                              [inputPlaceholder]="field.name"
                              [inputLabel]="field.name"
                              (onInputChange)="updateTrafficSystemMetadataField(field.uuid, $event)" />
            </ng-container>
            <ng-container *ngIf="field.url">
              <scope-ui-autocomplete
                [options]="(traffickingService.trafficSystemEntityMetadataSearch$[field.uuid] | async)!"
                [label]="field.name"
                [filterByAction]="true"
                [optionFn]="traffickingService.trafficFieldOption"
                (onSearch)="traffickingService.onSearchTrafficField($event, field.uuid, currentScope.identity.id)"
                (onSelectionChange)="selectTrafficField($event, field.uuid)"/>
            </ng-container>
          </div>
        </ng-container>

        <scope-ui-duration
          class="pt-6 block"
          [has-setting]="'SCOPE__START_END_DATE'"
          [(startDate)]='newDeliverable.startDate'
          [(endDate)]='newDeliverable.endDate'
          [(selectedPeriod)]='selectedPeriod' >
        </scope-ui-duration>
      </ng-container>
    </form>
    <div  *ngIf="!showMapping && nextModalStep">
      <p>
        The rate-card of the selected {{'deliverable|l' | lang }} is different to the {{'scope' | lang | lowercase }}'s rate-card.
      </p>
      <br>
      <p>
        It seems that the {{'deliverable|l' | lang }} '{{deliverableName}}' uses the rate-card '{{sourceRatecard.name}}(v.{{sourceRatecard.version}})', which has a different set of resources compared to the rate-card
        '{{targetRatecard.name}}(v.{{targetRatecard.version}})' used in the {{'scope' | lang | lowercase }} '{{currentScope.name}}'.
      </p>
      <br>
      <p>
        This means you will need to resolve the inconsistency between the rate-cards '{{sourceRatecard.name}}(v.{{sourceRatecard.version}})' and '{{targetRatecard.name}}(v.{{targetRatecard.version}})'. Would you like to try?
      </p>
    </div>
    <map-roles
      *ngIf="showMapping && nextModalStep"
      [sourceRateCard]='sourceRatecard'
      [ratecard]='targetRatecard'
      [targetRoles]='targetRoles'
      [mappingRoles]='mappingRoles'>
    </map-roles>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="flex-col" style="padding-bottom: 40px">
  <div class="flex w-full pt-6 justify-end">
    <button class='cancel-btn' mat-button mat-dialog-close>
      <span>Cancel</span>
    </button>
    <button class="confirm ml-4" *ngIf='!nextModalStep' [disabled]="!isFormValid()" (click)="confirm()">
      <span>Set up before adding</span>
    </button>
    <button class="confirm ml-4" *ngIf='!nextModalStep' [disabled]="!isFormValid()" (click)="confirm(true)">
      <span>Quick add</span>
    </button>
    <button class="confirm ml-4" *ngIf='nextModalStep && !showMapping' (click)="showRemapDialog()">
      <span>Continue</span>
    </button>
    <button class="confirm ml-4" *ngIf='nextModalStep && showMapping' (click)="insertWithRemapRoles()">
      <span>Insert</span>
    </button>
  </div>
  <div class="custom-del-button mt-6 flex" style="justify-content: center;" *ngIf='!isNewCustomDeliverable && !nextModalStep'>
    <button mat-button (click)="isNewCustomDeliverable = true; switchForm()">

      <span class='tracking-normal font-newJuneBold flex items-center' style='color: #333844'>
        <mat-icon class="material-symbols-rounded orange mb-1">add</mat-icon>
        Create Custom {{ isFixedFee ? 'Fixed Fee' : ('deliverable' | lang)}}
      </span>
    </button>
  </div>
  <div class="custom-del-button mt-6 flex" style="justify-content: center;" *ngIf='isNewCustomDeliverable && !nextModalStep'>
    <button mat-button (click)="isNewCustomDeliverable = false; switchForm()">

      <span class='tracking-normal font-newJuneBold flex items-center' style='color: #333844'>
        <mat-icon class="material-symbols-rounded orange mb-1">add</mat-icon>
        Add Existing {{ isFixedFee ? 'Fixed Fee' : ('deliverable' | lang)}}
      </span>
    </button>
  </div>
</mat-dialog-actions>

