import { Injectable } from '@angular/core'
import { Observable } from 'rxjs/internal/Observable'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { environment } from '@envs/environment'
import { Deliverable } from '@app/features/scoping/models/deliverable.model'
import { Page } from '@app/features/scoping/models/page.model'
import { ScopeVersion } from '@core/model/scope-version'
import { catchError, map } from 'rxjs'
import { plainToClassFromExist, plainToInstance } from 'class-transformer'
import { FolderVersion } from '@core/model/folder-version.model'
import { TypedFilterValue } from "@core/model/filter-option.interface";
import { FindRolesSearchParams } from '@core/model/definitions/findRoleSearchParams.interface'
import { RatecardVersion } from '@app/features/scope-overview/model/ratecard-version.model';
import { SecondParty } from '@core/model/second-party.model';

@Injectable({ providedIn: 'root' })
export class MyScopesService {
  private readonly BASE_API_URL: string = environment.scopeApiUrl

  constructor(private http: HttpClient) {}

  findRoles(scopeId: string, searchFilter?: FindRolesSearchParams) {
    return this.http.get<any>(`${this.BASE_API_URL}scope/${scopeId}/roles`, { params: searchFilter as {[key: string]: string} })
  }

  revertRoles(scopeId: string, revertId: string) {
    return this.http.post<any>(`${this.BASE_API_URL}scope/${scopeId}/roles/replacements/revert`, [revertId])
  }

  getRateCardByScope(scopeId: string) {
    return this.http.get<any>(`${this.BASE_API_URL}scope/${scopeId}/ratecard`)
      .pipe(map((api) => plainToInstance(RatecardVersion, api)))
  }

  getRateCard(rateCardId: string) {
    return this.http.get<any>(`${this.BASE_API_URL}company/ratecard/${rateCardId}`)
  }

  getOfficeLocations() {
    return this.http.get<any>(`${this.BASE_API_URL}office-locations`)
  }

  getScopeTemplates() {
    return this.http.get<any>(`${this.BASE_API_URL}scope/templates`)
  }

  getScopeTypes() {
    return this.http.get<any>(`${this.BASE_API_URL}scope-type`)
  }

  initiateScopeVersionCustomFields() {
    return this.http.get<any>(`${this.BASE_API_URL}scope/custom-fields/initiate`)
  }

  initiateTrafficSystem() {
    return this.http.get<any>(`${this.BASE_API_URL}company/traffic-system`)
  }

  replaceRoles(scopeId: string, roles: any, roleId: number, roleReplaceId: number): Observable<any> {
    const httpHeaders = new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json')

    return this.http
      .post<any>(`${this.BASE_API_URL}scope/${scopeId}/roles/${roleId}/replace/${roleReplaceId}`, roles, {
        headers: httpHeaders,
      })
      .pipe(
        catchError((error: any) => {
          console.error('Error:', error)
          return error
        })
      )
  }

  getMyScopes(filters: any, params: any): Observable<Page<ScopeVersion>> {
    let httpParams = new HttpParams()
    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key] !== undefined) {
        httpParams = httpParams.set(key, params[key])
      }
    }

    return this.http
      .post<Page<ScopeVersion>>(`${this.BASE_API_URL}scope/my-scopes`, filters, { params: httpParams })
      .pipe(map((api) => plainToClassFromExist(new Page<ScopeVersion>(ScopeVersion), api)))
  }

  getFolders(filters: any, params?: any): Observable<any> {
    let httpParams = new HttpParams()

    for (const key in params) {
      if (params.hasOwnProperty(key) && params[key] !== undefined) {
        httpParams = httpParams.set(key, params[key])
      }
    }

    return this.http
      .post<any>(`${this.BASE_API_URL}sow/my-folders`, filters, { params: httpParams })
      .pipe(map((api) => plainToClassFromExist(new Page<FolderVersion>(FolderVersion), api)))
  }

  getScopesHighlights(lastLoginDate: string): Observable<any> {
    return this.http.get<any>(`${this.BASE_API_URL}scope/highlights/${lastLoginDate}`, {})
  }

  searchDeliverables(searchText: string): Observable<TypedFilterValue[]> {
    return this.http
      .get<TypedFilterValue[]>(`${this.BASE_API_URL}scope/deliverable-search`, { params: { search: searchText } })
      .pipe(map((api) => plainToInstance(TypedFilterValue, api)))
  }

  searchComponents(searchText: string): Observable<TypedFilterValue[]> {
    return this.http
      .get<TypedFilterValue[]>(`${this.BASE_API_URL}scope/component-search`, { params: { search: searchText } })
      .pipe(map((api) => plainToInstance(TypedFilterValue, api)))
  }

  getDeliverableSummaries(scopeId: number): Observable<Deliverable[]> {
    return this.http
      .get<Deliverable[]>(`${this.BASE_API_URL}scope/${scopeId}/deliverables/summary`, {})
      .pipe(map((res) => res.map((api) => plainToInstance(Deliverable, api))))
  }

  getDeliverables(scopeId: number): Observable<Deliverable[]> {
    return this.http
      .get<Deliverable[]>(`${this.BASE_API_URL}scope/${scopeId}/deliverables`, {})
      .pipe(map((res) => res.map((api) => plainToInstance(Deliverable, api))))
  }

  getFolder(folderId: number): Observable<FolderVersion> {
    return this.http
      .get<any>(`${this.BASE_API_URL}sow/${folderId}`, {})
      .pipe(map((api) => plainToInstance(FolderVersion, api)))
  }

  archiveScope(scopeId: number): Observable<void> {
    return this.http.put<any>(`${this.BASE_API_URL}scope/${scopeId}/archive`, {})
  }

  unarchiveScope(scopeId: number): Observable<any> {
    return this.http.put<any>(`${this.BASE_API_URL}scope/${scopeId}/unarchive`, {})
  }

  archiveSow(sowId: number): Observable<void> {
    return this.http.put<any>(`${this.BASE_API_URL}sow/${sowId}/archive`, {})
  }

  unarchiveSow(sowId: number): Observable<any> {
    return this.http.put<any>(`${this.BASE_API_URL}sow/${sowId}/unarchive`, {})
  }

  closeScope(scopeId: number, comment: string): Observable<any> {
    return this.http.put<any>(`${this.BASE_API_URL}scope/${scopeId}/close`, { text: comment })
  }

  deleteScope(scopeId: number): Observable<any> {
    return this.http.delete<any>(`${this.BASE_API_URL}scope/${scopeId}`, {})
  }

  deleteFolder(folderId: number): Observable<any> {
    return this.http.delete<any>(`${this.BASE_API_URL}sow/${folderId}`, {})
  }

  assignScopeToFolder(scopeId: number, folderId: number): Observable<any> {
    return this.http.post<any>(`${this.BASE_API_URL}scope/${scopeId}/assign-to-sow/${folderId}`, {})
  }

  unassignScopeFromFolder(scopeId: number): Observable<any> {
    return this.http.post<any>(`${this.BASE_API_URL}scope/${scopeId}/un-assign-from-sow`, {})
  }

  createScope(scopeData: any) {
    return this.http.post<any>(`${this.BASE_API_URL}scope`, scopeData).pipe(map((api) => plainToInstance(ScopeVersion, api)))
  }

  getAllFeeItems() {
    return this.http.get(`${this.BASE_API_URL}company/fee-items`)
  }

  getSecondPartiesForScopeCreation() {
    return this.http.get<SecondParty[]>(`${this.BASE_API_URL}scope/second-parties`)
      .pipe(map((api) => plainToInstance(SecondParty, api)))
  }
}
