import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from '@app/core/model/company.model';
import { LanguageService } from '@app/core/service/language.service';
import { BehaviorSubject } from 'rxjs';
import {
  SNACKBAR_LENGTH_SHORT,
  SnackbarEventType,
  SnackbarService,
} from '@shared/utils/snackbar.service'
import { ScopeMetadataService } from '@core/service/scope-metadata.service'
import { AuthService } from '@core/service/auth.service'
import { EnvService } from '@envs/env-service'
import { ScopeUiModalComponent } from '@shared/components/ui-components/scope-ui-modal/scope-ui-modal.component';
import { ModalConfig } from '@core/model/modal-config.model';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class ScopeTraffickingService {
  exportReference: any = null
  abortedErrorMessage: string | null = null
  errorType: string | null = null
  deliverableErrors: any[] = []
  deliverableFailCount: number = 0
  deliverableSuccessCount: number = 0
  total: number = 0
  exportStatus = new ExportStatus()
  trafficMetadataLookupFields: any
  showAbortErrorDiagnostic: boolean = false
  isTrafficking: boolean
  trafficSystemEntityMetadataSearch$: any = {};

  private exportStatusSubject = new BehaviorSubject<ExportStatus>(this.exportStatus)
  exportStatusChanges = this.exportStatusSubject.asObservable()

  constructor(private langService: LanguageService,
              private snackbarService: SnackbarService,
              private envService: EnvService,
              private http: HttpClient,
              private scopeMetadataService: ScopeMetadataService,
              private authService: AuthService,
              private dialog: MatDialog) {
    this.exportStatus.setConfirmationPending()
  }

  // TODO: Integrations Page.
  // initTrafficMetadataFields() {
  //   this.authService.getAccount().subscribe(user => {
  //     if (user.company.trafficSystemSettings) {
  //       const trafficSystem = user.company.trafficSystemSettings.getTrafficSystem();
  //       if (trafficSystem && trafficSystem.externalTrafficSystemConfig.scopeMetadataLookupConfig) {
  //         this.trafficMetadataLookupFields = trafficSystem.externalTrafficSystemConfig.scopeMetadataLookupConfig.definitions;
  //       }
  //     }
  //   });
  // }
  exportDiagnosticData(identityId: number, reference: string): void {
    console.log('reference:', reference);

    const url = this.envService.scopeApiUrl + 'exportScopeTrafficData' // Replace with your actual API endpoint
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = { identityId: identityId, reference: reference };

    this.http.post(url, params, { headers: headers, responseType: 'blob', observe: 'response' })
      .subscribe({
        next: (response) => {
          const blob = new Blob([response.body!], { type: 'text/plain' });
          const contentDisposition = response.headers.get('Content-Disposition');
          let filename = 'fileData.txt';

          if (contentDisposition) {
            const matches = /filename="([^"]+)"/.exec(contentDisposition);
            if (matches != null && matches[1]) {
              filename = matches[1];
            }
          }

          const downloadUrl = window.URL.createObjectURL(blob);
          console.log('downloadUrl:', downloadUrl);
          const a = document.createElement('a');
          a.href = downloadUrl;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(downloadUrl);
        },
        error: (error) => {
          console.error(error)
          this.snackbarService.showSnackbar(`An error occurred during data export`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.ERROR)
        }
      });
  }

  trafficToIntegration(identityId: number, integrationNames: string[]) {

    const url = this.envService.scopeApiUrl + '/scope/traffic'
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const params = { scopeId: identityId,integrations: integrationNames};

    this.http.post(url, params, { headers: headers, responseType: 'blob', observe: 'response' })
      .subscribe({
        next: (response) => {
         return response
        },
        error: (error) => {
          console.error(error)
          this.snackbarService.showSnackbar(`An error occurred during data export`, SNACKBAR_LENGTH_SHORT, SnackbarEventType.ERROR)
        }
      });
  }


  assertActiveSystemConfigured(company: Company) {
    if (!company.trafficSystemSettings) {
      this.snackbarService.showPermanentSnackbar('You currently do not have a Traffic System configured', SnackbarEventType.WARNING)
      throw new Error('Traffic system not configured ')
    }
  }

  show(config, renderDownloadFile) {
    let dialog = this.dialog.open(ScopeUiModalComponent, {
      data: new ModalConfig(
        `Traffic System Error`,
        `${config.message}\n${config.exceptionMessage}\n${config.invalidItems && config.invalidItems.length > 0 ?
          "Entity ids: " + config.invalidItems : ""}`,
        `Sync`,
        'Download Traffic System Response',
        () => {
          dialog.close()
        },
        () => {
          if (config.data) {
            let blob = new Blob([config.data], { type: 'text/plain' });
            let name = "JsonData.txt";
            let downloadUrl = (window.URL || window.webkitURL).createObjectURL(blob);
            let a = $(`<a href='${downloadUrl}' download="${name}"></a>`);
            a[0].click();
          } else {
            renderDownloadFile()
          }
        },
        undefined,
        false,
        true,
        !!config.downloadFile
      ),
    })
  }

  validateServerError(response, closeCallback, renderDownloadFile){
    if (response.status == 400){
      if (response.data.exception == "com.thevirtugroup.scope.domain.service.trafficsystem.TrafficSystemInactiveException") {
        if (closeCallback){
          closeCallback();
        }
        this.show({
          message: "Your Traffic System is disabled",
          downloadFile: false
        }, renderDownloadFile);
        throw new Error("Traffic system inactive.");
      }
      else if (response.data.exception == "com.thevirtugroup.scope.domain.model.exception.TrafficSystemAuthenticationException") {
        if (closeCallback){
          closeCallback();
        }
        this.show({
          message: "Your Traffic System credentials are invalid. Please configure this in the integration settings.",
          downloadFile: false
        }, renderDownloadFile);
        throw new Error("Traffic system invalid credentials.");
      }
      else if (response.data.invalidItems) {
        if (closeCallback){
          closeCallback();
        }
        this.show({
          message: this.getInvalidDataErrorMessage(response.data.errorType),
          data: response.data.data,
          invalidItems: response.data.invalidItems,
          downloadFile: true
        }, renderDownloadFile);
        throw new Error("Traffic system invalid credentials.");
      }
      else {
        if (closeCallback){
          closeCallback();
        }
        this.show({
          message: "Problem occurred with your traffic system",
          exceptionMessage: response.data.message ? response.data.message : "ERROR: " + response.data,
          downloadFile: false
        }, renderDownloadFile);
        throw new Error("Traffic system error.");
      }
    }
  }

  getInvalidDataErrorMessage(code){
    switch(code){
      case "ENTITY_ID_OR_NAME":
        return "Entity IDs or Names are missing or invalid";
      case "ROLE_RATE_CURRENCY":
        return "Rates of roles are missing or invalid format"
      case "ENTITY_OTHER":
        return "Entity(s) contain invalid data, please ensure traffic data is inline with API documentation"
      case "DUPLICATE_ENTITY_ID":
        return "Entity ID duplication found"
      case "INVALID_JSON":
        return "Json data response, please review traffic data provided"
    }
    return ""
  }

  getServerErrorMessage(error: any): void {
    switch (error.error.errorType) {
      case 'SCOPE_INCOMPLETE':
        this.abortedErrorMessage = `${this.langService.get('deliverable.p')} in this ${this.langService.get(
          'scope'
        )} are incomplete.`
        break
      case 'SCOPE_ALREADY_IN_SYNC':
        this.abortedErrorMessage = `This ${this.langService.get('scope')} has no changes.`
        break
      case 'SCOPE_STATUS_NON_TRAFFICABLE':
        this.abortedErrorMessage = `This ${this.langService.get('scope')} cannot be trafficked at this approval status.`
        break
      case 'SCOPE_NOT_TRAFFICABLE':
        this.abortedErrorMessage = `This ${this.langService.get('scope')} cannot be trafficked.`
        break
      case 'TRAFFIC_SYSTEM_NOT_CONFIGURED':
      case 'INACTIVE_CONNECTIONS':
        this.abortedErrorMessage = `This ${this.langService.get('scope')} cannot be trafficked:\n ${error.error.errorMessage}`;
        break
      case 'CONNECTION_NOT_FOUND':
        this.abortedErrorMessage = `This ${this.langService.get('scope')} cannot be trafficked:\n
          ${error.error.errorMessage}\n If the problem persists, please contact Scope support.`;
        break
      case 'EXTERNAL':
        this.abortedErrorMessage = `This ${this.langService.get('scope')} cannot be trafficked.
          If the problem persists, please contact Scope support.`;
        break
      default:
        this.abortedErrorMessage = 'Please contact Scope support.'
    }
  }

  getAbortedErrorMessage(errorType: string): string {
    switch (errorType) {
      case 'AUTHORISATION':
        return 'Invalid authentication credentials. Please update your traffic integration credentials.'
      case 'INTERNAL':
        return 'An internal error has occurred. Please contact support for further details.'
      case 'ALREADY_IN_SYNC':
        return `${this.langService.get('scope')} is in sync with the traffic system and cannot be trafficked.`
      case 'TRAFFIC_SYSTEM':
        return 'An error has occurred at the traffic system. Please contact support for further details.'
      case 'NO_HISTORY_FOR_DELTA_UPDATE':
        return 'No history for update delta. Cannot identify changes to update this scope.'
      case 'NO_TRAFFIC_REFERENCE':
        return 'No traffic reference received. An error has occurred at the traffic system. Please contact support for further details.'
      case 'EXPORTER_NOT_CONFIGURED':
        return 'Exporter not configured.'
      default:
        return 'Please contact support for further details.'
    }
  }

  setPendingStart(): void {
    this.exportStatus.reset()
    this.exportStatus._pendingStart = true
    this.exportStatusSubject.next(this.exportStatus)
  }

  setConfirmationPending(): void {
    this.exportStatus.reset()
    this.exportStatus._confirmationPending = true
    this.exportStatusSubject.next(this.exportStatus)
  }

  setInProgress(): void {
    this.exportStatus.reset()
    this.exportStatus._showInProgress = true
    this.exportStatusSubject.next(this.exportStatus)
  }

  setExportAborted(): void {
    this.exportStatus.reset()
    this.exportStatus._showExportAborted = true
    this.exportStatusSubject.next(this.exportStatus)
  }

  setExportComplete(): void {
    this.exportStatus.reset()
    this.exportStatus._showExportComplete = true
    this.exportStatusSubject.next(this.exportStatus)
  }

  getTrafficFields() {
    if (this.authService.loggedInUser?.company.trafficSystemSettings){
      const trafficSystem = this.authService.loggedInUser.company.trafficSystemSettings.trafficSystem;
      if (trafficSystem && trafficSystem.externalTrafficSystemConfig.deliverableMetadataLookupConfig){
        return trafficSystem.externalTrafficSystemConfig.deliverableMetadataLookupConfig.definitions
      }
    }
    return [];
  }

  onSearchTrafficField(event: { searchString: string }, uuid: string, scopeIdentityId: number) {
    this.trafficSystemEntityMetadataSearch$[uuid] = this.scopeMetadataService.callDeliverableMetadataLookupUrl(uuid, scopeIdentityId, event.searchString);
  }

  trafficFieldOption(item: any) {
    return item ? `${item.name} (${item.description})` : '';
  }
}

class ExportStatus {
  _confirmationPending = false
  _pendingStart = false
  _showInProgress = false
  _showExportAborted = false
  _showExportComplete = false

  reset(): void {
    this._confirmationPending = false
    this._pendingStart = false
    this._showInProgress = false
    this._showExportAborted = false
    this._showExportComplete = false
  }

  setPendingStart(): void {
    this.reset()
    this._pendingStart = true
  }

  setConfirmationPending(): void {
    this.reset()
    this._confirmationPending = true
  }

  setInProgress(): void {
    this.reset()
    this._showInProgress = true
  }

  setExportAborted(): void {
    this.reset()
    this._showExportAborted = true
  }

  setExportComplete(): void {
    this.reset()
    this._showExportComplete = true
  }
}
