import { Action, createReducer, on } from '@ngrx/store';
import { CompanyManagementState } from '../../models/company-management.state.model';
import { OutputTemplate } from '@app/features/scope-overview/model/output-template.model';
import {
  CompanyManagementActions
} from '@app/features/company-management/store/actions/company-management.actions';

export const initialState: CompanyManagementState = {
  outputTemplates: [],
  loadingOutputTemplates: false,
  loadedOutputTemplates: false
};

function getOutputTemplatesHandler(state: CompanyManagementState) {
  return {
    ...state,
    loadingOutputTemplates: true,
    loadedOutputTemplates: false,
  };
}

function getOutputTemplatesSuccessHandler(state: CompanyManagementState, action: { outputTemplates: OutputTemplate[] }) {
  return {
    ...state,
    outputTemplates: action.outputTemplates,
    loadingOutputTemplates: false,
    loadedOutputTemplates: true,
  };
}

function getOutputTemplatesFailHandler(state: CompanyManagementState, action: { error: Error }) {
  return {
    ...state,
    loadingOutputTemplates: false,
    loadedOutputTemplates: true,
    error: action.error,
  };
}

export const CompanyManagementReducer = createReducer(
  initialState,
  on(CompanyManagementActions.getOutputTemplates, getOutputTemplatesHandler),
  on(CompanyManagementActions.getOutputTemplatesSuccess, getOutputTemplatesSuccessHandler),
  on(CompanyManagementActions.getOutputTemplatesFail, getOutputTemplatesFailHandler)
);

export function reducer(state: CompanyManagementState | undefined, action: Action): CompanyManagementState {
  return CompanyManagementReducer(state, action);
}
